// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch
} from '../../../common/edliy_utils-circle';
const Boxes = {
    box1: function () {
      JXG.Options.point.showInfoBox=false;
      JXG.Options.point.highlight=false;
      JXG.Options.text.highlight=false;
      JXG.Options.line.highlight=false;
      JXG.Options.polygon.highlight=false;
      JXG.Options.text.fixed=true;
      JXG.Options.curve.highlight=false;
      JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
    var brd3 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 10, 10, -10],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
    brd3.options.layer['image'] =14;
    makeResponsive(brd3);
    placeTitle(brd3, 'Newton\'s Second Law', '(Calculate Forces & Acceleration)');
    placeGravity(brd3, [5, 5],[5, 3], 'g');
//brd3.create('text', [-6, 9., '<b> Free Body Diagram <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(32*brd3.canvasWidth/500.)}, fixed:true});
//EXTREME POINT
var Pt1 = brd3.create('point', [-10,0],{face:'o' , name:'Pt2D', size:0,withLabel:false, fixed:false});
var Pt2 = brd3.create('point', [-10, 0],{face:'o' , name:'Pt22D', size:0,withLabel:false, fixed:false});

var line = brd3.create('segment', [Pt1,Pt2],{visible: false, strokeWidth:1,dash:1, strokeColor:'black', fixed:true});
var table = brd3.create('glider', [-10,-4, line], {visible: false, face: 'o' , size:3, name:'', strokeColor:'black'});


//ORANGE TABLE
brd3.create('polygon', [[-10,-15],
                         [0,-15],
                         [0,0],
                         [function(){return table.X()}, function(){return table.Y()}]],
                        {vertices:{visible:false, fixed:true}, borders:{visible:false}, fillColor: '#ffa42a', fillOpacity:1.0});

//ANGLE
function angle(){
    return Math.atan(10/Math.abs(table.Y()))
};
brd3.create('text', [-2, -2, function(){return "&alpha;= "+ (angle()*180/Math.PI).toFixed(1)}], {fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var circc=brd3.create('arc', [[0,0],[function(){return -2*Math.sin(angle())},function(){return -2*Math.cos(angle())}], [0,-2]],{visible:true, strokeWidth:1, strokeColor:'black', dash:1, center:{visible:false}, radiuspoint:{visible:false}, anglepoint:{visible:false}});

//SLIDING AND HANGING ELEMENTS

var Pt3 = brd3.create('point', [0,0],{face:'o' , name:'Pt2D', size:0,withLabel:false, fixed:false});
var Pt4 = brd3.create('point', [function(){return table.X()}, function(){return table.Y()}],{face:'o' , name:'Pt22D', size:0,withLabel:false, fixed:false});
var line2 = brd3.create('segment', [Pt3,Pt4],{visible: false, strokeWidth:1,dash:1, strokeColor:'black', fixed:true});
var slidingBox = brd3.create('glider', [-6,0, line2], {visible: false, face: 'o' , size:3, name:'', strokeColor:'black'});
var slidingBoxIm = brd3.create('image', ['/assets/fbd/kettlebell.svg', [function(){return slidingBox.X()-0.875},function(){return slidingBox.Y()}], [1.75,1.75]],{fixed: true, highlight:false});
brd3.create('image', ['/assets/fbd/hands.svg',[()=>slidingBox.X()-2, ()=>slidingBox.Y()+2], [2, 2]],{rotate:-90});
var hangingBox = brd3.create('point', [0.5,-4],{face:'o' , name:'Pt22D', size:0,withLabel:false, fixed:false});
var hangingBoxIm = brd3.create('image', ['/assets/fbd/bucket.svg', [function(){return hangingBox.X()-0.4},function(){return hangingBox.Y()}], [1.75,1.75]], {fixed: true, highlight:false});
//CORDS
var Pt5 = brd3.create('point', [0,0.8],{face:'o' , name:'Pt2D', size:0,withLabel:false, fixed:false});
var Pt6 = brd3.create('point', [function(){return slidingBox.X()},function(){return slidingBox.Y()+0.8}],{face:'o' , name:'Pt2D', size:0,withLabel:false, fixed:false});
var li1 = brd3.create('segment', [Pt5,Pt6], {strokeColor: 'blue'});
//
var Pt7 = brd3.create('point', [1,0.5],{face:'o' , name:'Pt2D', size:0,withLabel:false, fixed:false});
var Pt8 = brd3.create('point', [function(){return hangingBox.X()+0.5},function(){return hangingBox.Y()+1}],{face:'o' , name:'Pt2D', size:0,withLabel:false, fixed:false});
var li2 = brd3.create('segment', [Pt7,Pt8], {strokeColor: 'blue'});
//ROTATE PULLEY
var pulley = brd3.create('point', [0,0],{face:'o' , name:'pulley', size:0,withLabel:false, fixed:true});
var p2 = brd3.create('point', [0.5,0.5], {visible:false, size:8, name:'offset', opacity:0.3});
var p3 = brd3.create('point', [0.5,0.5], {visible:false, size:8, name:'p3', opacity:0.3});
var pulleyIm = brd3.create('image', ['/assets/fbd/pulley.svg',[0, 0],[1,1]], {fixed: true});
var tRot = brd3.create('transform', [function(){return hangingBox.Y()}, p3], {type:'rotate'});
tRot.bindTo(pulleyIm);
//SLIDERS
//var mass = brd3.create('slider', [[3,5], [6,5], [1, 5, 10]],{visible:false});
//var Mass = brd3.create('slider', [[3,4], [6,4], [1, 5, 10]],{visible:false});
//brd3.create('text', [1, 6.1, '&mu;'], {fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//brd3.create('text', [1, 5.4, "bucket's <br> mass (m_1)"], {fixed: true, fontSize:function(){return 16*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'},{visible:false});
//brd3.create('text', [1, 4.0, "kettlebell's <br> mass (m_2)"], {fixed: true, fontSize:function(){return 16*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'},{visible:false});
//brd3.create('text', [1, 4.8, ''], {fixed: true, fontSize:function(){return 16*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//brd3.create('text', [1, 3.8, ''], {fixed: true, fontSize:function(){return 16*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//brd3.create('text', [7, 5, '[kg]'], {fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//brd3.create('text', [7, 4, '[kg]'], {fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//Arrows sliding Box
var p0arrowS = brd3.create('point', [function(){return slidingBox.X()},function(){return slidingBox.Y()+0.8}],{visible: function(){if(i>=2 ){return true} else{return false}}, name:'', size:0, fixed:true, label:{offset:[0, -20], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var pWeightX = brd3.create('point', [function(){return p0arrowS.X()-2*Math.cos(angle())*Math.sin(angle())},function(){return p0arrowS.Y()-2*Math.cos(angle())*Math.cos(angle())}],{visible: false,name:'Wx', size:0, fixed:true, label:{offset:[0, -20], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pWeightY = brd3.create('point', [function(){return p0arrowS.X()+2*Math.sin(angle())*Math.cos(angle())},function(){return p0arrowS.Y()-2*Math.sin(angle())*Math.sin(angle())}],{visible: false,name:'W', size:0, fixed:true, label:{offset:[0, -20], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pWeight = brd3.create('point', [function(){return p0arrowS.X()},function(){return p0arrowS.Y()-2}],{visible: function(){if(i>=2 ){return true} else{return false}},name:'W_2', size:0, fixed:true, label:{offset:[0, -5],fontSize:function(){return 18*brd3.canvasHeight/800}}});
var k =0;
var normal = brd3.create('point', [function(){return p0arrowS.X()-2*Math.sin(angle())*Math.cos(angle())},function(){return p0arrowS.Y()+2*Math.sin(angle())*Math.sin(angle())}],{visible: function(){if(i>=2 ){return true} else{return false}}, name:'N', size:0, fixed:true, label:{offset:[0, 25], fontSize:function(){return 18*brd3.canvasHeight/800}}});
var arrowNormal = brd3.create('arrow', [p0arrowS, normal] , {visible: function(){if(i>=2 ){return true} else{return false}} });

var arrowGravityX = brd3.create('arrow', [p0arrowS, pWeightX] , {visible: false});
var arrowGravityY = brd3.create('arrow', [p0arrowS, pWeightY] , {visible: false});
var arrowGravity = brd3.create('arrow', [p0arrowS, pWeight] , {visible: function(){if(i>=2 ){return true} else{return false}}});

var pTension1 = brd3.create('point', [function(){return p0arrowS.X()+2*Math.sin(angle())},function(){return p0arrowS.Y()+2*Math.cos(angle())}],{visible: function(){if(i>=2){return true} else{return false}}, name:'T_2', size:0, fixed:true, label:{offset:[0, 30], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var arrowTension = brd3.create('arrow', [p0arrowS, pTension1], {visible: function(){if(i>=2 ){return true} else{return false}}});
arrowTension.setLabel('T_2 is the tension in the rope.')
arrowTension.label.setAttribute({visible:false, offset:[0, 15], CssStyle:'fontFamily:Oswald', fontSize:12});
arrowTension.on('over', function () {k=2; this.label.setAttribute({visible:true});});
arrowTension.on('out', function () {k=0; this.label.setAttribute({visible:false});});

//Arrows hanging box
var p0arrow = brd3.create('point', [function(){return hangingBox.X()+0.5},function(){return hangingBox.Y()+0.5}],{visible: function(){if(i>=1 ){return true} else{return false}}, name:'', size:0, fixed:true, label:{offset:[0, -20], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var pWeight2 = brd3.create('point', [function(){return p0arrow.X()},function(){return p0arrow.Y()-2}],{visible: function(){if(i>=1 ){return true} else{return false}}, name:'W_1', size:0, fixed:true, label:{offset:[0, -10], fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var arrowGravity2 = brd3.create('arrow', [p0arrow, pWeight2] , {visible: function(){if(i>=1 ){return true} else{return false}}});
var pTension2 = brd3.create('point', [function(){return p0arrow.X()},function(){return p0arrow.Y()+2}],{visible: function(){if(i>=1 ){return true} else{return false}}, name:'T_1', size:0, fixed:true, label:{offset:[0, 10], fontSize:function(){return 20*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var arrowTension2 = brd3.create('arrow', [p0arrow, pTension2] , {visible: function(){if(i>=1 ){return true} else{return false}}});
arrowTension2.setLabel('T is the tension in the rope (act upwards)')
arrowTension2.label.setAttribute({visible:false, offset:[0, 15], CssStyle:'fontFamily:Oswald', fontSize:12});
arrowTension2.on('over', function () {k=1; this.label.setAttribute({visible:true});});
arrowTension2.on('out', function () {k=0; this.label.setAttribute({visible:false});});
arrowGravity2.setLabel('W_1 is the weight of the bucket (= m_1g). ')
arrowGravity2.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:12});
arrowGravity2.on('over', function () {this.label.setAttribute({visible:true});});
arrowGravity2.on('out', function () {this.label.setAttribute({visible:false});});

//HAND
var hand = brd3.create('image', ['/assets/fbd/hand2.svg', [-4,0.5], [1.5,1.5]], {visible: function(){if(i>=4){return true} else{return false}}, fixed: false, rotate: 90});

//ARC
var centro = brd3.create('point', [0.4,0.3],{visible: false});
var final = brd3.create('point', [1,0.5],{visible: false});
var ppio = brd3.create('point', [0,0.8],{visible: false});
var circc1=brd3.create('arc', [centro,final,ppio],{fixed: true, visible:true, strokeWidth:2, strokeColor:'blue', dash:0, center:{visible:false}, radiuspoint:{visible:false}, anglepoint:{visible:false}});

//REDO AND UNDO
var redo = brd3.create('image', ['/assets/fbd/redo.svg', [3.4,-8.5], [1.2,1.2]], {visible: true, fixed: true});
var undo = brd3.create('image', ['/assets/fbd/undo.svg', [2,-8.5], [1.2,1.2]], {visible: true, fixed: true});

var i =0;
var maxSteps = 3;

function updateStepTexts(){
    if (i<=0){
        return;
    }
    if(i>=1){
        document.getElementById('step1').innerHTML = "" + text1.plaintext;

    }
    if(i>=2){
        document.getElementById('step2').innerHTML = "" + text2.plaintext;

    }
    if(i>=3){
        document.getElementById('step3').innerHTML = "" + text3_0.plaintext;
        document.getElementById('step3-1').innerHTML = "" + text3_1.plaintext;
		document.getElementById('step3-2').innerHTML = "" + text3_2.plaintext;
        document.getElementById('step3-3').innerHTML = "" + text3_3.plaintext;
    }
}

redo.on('down',function(){
    i = i+1;
    if (i > 3){
        i = 3;
    }
    updateStepTexts();
});

undo.on('down',function(){
    i=i-1;
    if (i < 0){
        i = 0;
    }
    document.getElementById('step1').innerHTML = "";
    document.getElementById('step2').innerHTML = "";
	document.getElementById('step3').innerHTML = "";
    document.getElementById('step3-1').innerHTML = "";
    document.getElementById('step3-2').innerHTML = "";
    document.getElementById('step3-3').innerHTML = "";
    updateStepTexts();
});


//FIRST STEP
var text1 = brd3.create('text', [-9, -3, "<b> Step 1 </b>: Apply Newton's 2^n^d law on the bucket<br>  &sum;F_y = m_1a <br> T - W_1 = m_1(-a) <br> T_1 - m_1g = -m_1a <br> T_1 = m_1(g - a) "], {visible: false, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//SECOND STEP
var text2 = brd3.create('text', [-5, -3, "<b>Step 2 </b>: Apply Newton's 2^n^d law on the kettlebell <br> &sum;F_x = m_2a <br> T_2 = m_2a"], {visible: false, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//THIRD STEP
var text3_0 = brd3.create('text', [-7, -3, "<b>Step 3 </b>: Pulley is frictionless. So T_1 = T_2 = T"], {visible: false, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text3_1 = brd3.create('text', [-7, -3,  " m_1(g - a) = m_2a"], {visible: false, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text3_2 = brd3.create('text', [-7, -3,  "a = m_1g/(m_1+m_2)"], {visible: false, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text3_3 = brd3.create('text', [-7, -3,  "T = m_2a = m_1m_2g/(m_1+m_2)"], {visible: false, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//MOVEMENT
hand.on('up', function(){
    if(tension()>weightSLidingBoxX()){
       return slidingBox.moveTo([-0.3,0],1000),
              hangingBox.moveTo([0.5,-8],1000),

              pTension2.setAttribute({visible: false}),
              p0arrow.setAttribute({visible: false}),
              arrowTension2.setAttribute({visible: false}),
              arrowGravity2.setAttribute({visible: false}),
              pWeight2.setAttribute({visible:false}),

              p0arrowS.setAttribute({visible:false}),
              pWeightY.setAttribute({visible:false}),
              arrowGravityY.setAttribute({visible:false}),
              pWeight.setAttribute({visible:false}),
              arrowGravity.setAttribute({visible:false}),

              pTension1.setAttribute({visible:false}),
              arrowTension.setAttribute({visible:false}),

              normal.setAttribute({visible:false}),
              arrowNormal.setAttribute({visible:false}),

              textAcc.setAttribute({visible:true}),
              textWy.setAttribute({visible:true}),
              textTension.setAttribute({visible:true}),
              textWBucket.setAttribute({visible:true})

    }
    else if(tension()<weightSLidingBoxX() && angle()!= Math.PI/2 ){
        return slidingBox.moveTo([-8*Math.sin(angle()),-8*Math.cos(angle())],1000),
               hangingBox.moveTo([0.5,-1],1000),
               pTension2.setAttribute({visible: false}),
              p0arrow.setAttribute({visible: false}),
              arrowTension2.setAttribute({visible: false}),
              arrowGravity2.setAttribute({visible: false}),
              pWeight2.setAttribute({visible:false}),

              p0arrowS.setAttribute({visible:false}),
              pWeightX.setAttribute({visible:false}),
              arrowGravityX.setAttribute({visible:false}),
              pWeightY.setAttribute({visible:false}),
              arrowGravityY.setAttribute({visible:false}),
              pWeight.setAttribute({visible:false}),
              arrowGravity.setAttribute({visible:false}),

              pTension1.setAttribute({visible:false}),
              arrowTension.setAttribute({visible:false}),

              normal.setAttribute({visible:false}),
              arrowNormal.setAttribute({visible:false})

    }

});

//RESET BUTTON
var reset = brd3.create('image', ['/assets/fbd/reset.svg',[-10,2.5],[1.2,1.2]], {visible: function(){if(i>=4){return true} else{return false}}, fixed: true});


reset.on('down',function(){
    mass.moveTo([4.3334,0],10),
    Mass.moveTo([4.3334,0],10),
    hangingBox.moveTo([0.5,-4],10),
    slidingBox.moveTo([-6,0],10),
    table.moveTo([-10,-4],10),
    hand.moveTo([-4,0.5],10),

    pTension2.setAttribute({visible: true}),
    p0arrow.setAttribute({visible: true}),
    arrowTension2.setAttribute({visible: true}),
    arrowGravity2.setAttribute({visible: true}),
    pWeight2.setAttribute({visible:true}),

    p0arrowS.setAttribute({visible:true}),
    pWeightY.setAttribute({visible:true}),
    arrowGravityY.setAttribute({visible:true}),
    pWeight.setAttribute({visible:true}),
    arrowGravity.setAttribute({visible:true}),

    pTension1.setAttribute({visible:true}),
    arrowTension.setAttribute({visible:true}),

    normal.setAttribute({visible:true}),
arrowNormal.setAttribute({visible:true})});

      },
}
export default Boxes;
